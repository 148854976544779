import { Routes, Route, BrowserRouter } from 'react-router-dom'
import { lazy, Suspense, useEffect, useState } from 'react'
import useWindowSize from './hooks/useWindowSize'
import Loading from './pages/Loading'
// Lazy load components
const Home = lazy(() => import('./pages/Home'))
const VirtualTour = lazy(() => import('./pages/VirtualTour'))
const NotFoundPage = lazy(() => import('./pages/404'))
const ExternalRedirect = lazy(() => import('./components/ExternalRedirects'))

export default function App() {
  const [vTourUrl, setVTourURl] = useState('')
  const [projectId, setProjectId] = useState(-1)

  const windowSize = useWindowSize()
  const _modes = {
    web: 'web',
    mobile: 'mobile',
  }
  const [mode, setMode] = useState(_modes.web)

  useEffect(() => {
    if (windowSize.width < 800) {
      if (mode !== _modes.mobile) {
        setMode(_modes.mobile)
      }
    } else {
      if (mode !== _modes.web) {
        setMode(_modes.web)
      }
    }
  }, [windowSize, mode, _modes])

  useEffect(() => {
    console.log('mode', mode)
  }, [mode])

  return (
    <BrowserRouter>
      {/* Suspense to handle loading states for lazy-loaded components */}
      <Suspense fallback={<Loading />}>
        <Routes>
          {/* Redirect '/' to external website aerialgis.com */}
          <Route path='/' element={<ExternalRedirect />} />

          {/* Not found page */}
          <Route path='*' element={<NotFoundPage />} />

          {/* Project page */}
          <Route
            path='/project'
            element={
              <Home
                setVTourURl={setVTourURl}
                projectId={projectId}
                setProjectId={setProjectId}
                mode={mode}
                _modes={_modes}
              />
            }
          />

          {/* Virtual tour page */}
          <Route
            path='/virtual-tour'
            element={
              <VirtualTour
                url={vTourUrl}
                projectId={projectId}
                mode={mode}
                _modes={_modes}
              />
            }
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}
