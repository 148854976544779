import React from 'react'
import Loader from '../components/Loader'

function Loading() {
  return (
    <div
      style={{
        background: '#e9f3fe', // Preferred background color
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Loader />
    </div>
  )
}
export default Loading
